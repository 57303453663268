import { Text, TextProps } from '@chakra-ui/react';
import { FC } from 'react';
import colors from '@/constants/theme/colors';
import { titleFont } from '@/fonts';

export interface TitleProps extends TextProps {}
const Title: FC<TitleProps> = ({ children, ...textProps }) => {
  return (
    <Text
      as="h1"
      fontWeight="600"
      color={colors.primary[300]}
      className={titleFont.className}
      letterSpacing="initial"
      {...textProps}
    >
      {children}
    </Text>
  );
};

export default Title;
