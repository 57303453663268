import { ComponentStyleConfig } from '@chakra-ui/react';

export const TextStyles: ComponentStyleConfig = {
  baseStyle: {
    letterSpacing: '-0.36px',
  },
  variants: {},
  defaultProps: {},
};

export default TextStyles;
