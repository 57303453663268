import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import type { RootState } from '@/store';
import { useSelector } from 'react-redux';
import { ICartProductItem } from '@/types/product';
import { ICartItem } from '@/types/cart';
import { checkIfPCartProductItemMatch } from '@/utils/product';
import { Dayjs } from 'dayjs';
import { IAuthModalMode } from '@/types/auth';
import { IPublicCartCheckoutResponse } from '../apis/app.carts';

export interface AppState {
  // drawers
  isCartDrawerOpen: boolean;

  // modals
  isRecycleModalOpen: boolean;
  isAuthModalOpen: boolean;
  authModalMode: IAuthModalMode;

  // stripe
  stripePaymentClientSecret: string;
  stripePaymentIntentId: string;

  priceLockTimerDeadline: Dayjs | null;
  priceLockTimerDiff: number;
  cartItems: ICartItem[];
  wishlistVariantIds: number[];

  // currency
  currencyCode: string;
  countryCode: string;
  currencyExchangeRate: number;

  // app search
  showingSearchInput: boolean;
  isSearchInputActive: boolean;
  searchInputValue: string;
  recentSearchTexts: string[];

  // public cart
  publicCart: IPublicCartCheckoutResponse | null;
}

const initialState: AppState = {
  // drawers
  isCartDrawerOpen: false,

  // modals
  isRecycleModalOpen: false,
  isAuthModalOpen: false,
  authModalMode: IAuthModalMode.login,

  // stripe
  stripePaymentClientSecret: '',
  stripePaymentIntentId: '',

  priceLockTimerDeadline: null,
  priceLockTimerDiff: 0,

  cartItems: [],
  currencyCode: 'USD',
  countryCode: 'US',
  currencyExchangeRate: 1,
  wishlistVariantIds: [],

  showingSearchInput: false,
  isSearchInputActive: false,
  searchInputValue: '',
  recentSearchTexts: [],

  publicCart: null,
};

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    // drawers
    closeCartDrawer: (state, action: PayloadAction<void>) => {
      state.isCartDrawerOpen = false;
    },
    openCartDrawer: (state, action: PayloadAction<void>) => {
      state.isCartDrawerOpen = true;
    },

    // modals
    closeRecycleModal: (state, action: PayloadAction<void>) => {
      state.isRecycleModalOpen = false;
    },
    openRecycleModal: (state, action: PayloadAction<void>) => {
      state.isRecycleModalOpen = true;
    },
    closeAuthModal: (state, action: PayloadAction<void>) => {
      state.isAuthModalOpen = false;
    },
    openAuthModal: (state, action: PayloadAction<{ mode: IAuthModalMode }>) => {
      const { mode } = action.payload;
      if (!!mode) {
        state.authModalMode = mode;
      } else {
        state.authModalMode = IAuthModalMode.login;
      }
      state.isAuthModalOpen = true;
    },

    // cart
    addCartItem: (
      state,
      action: PayloadAction<{ cartProductItem: ICartProductItem }>
    ) => {
      const { cartProductItem } = action.payload;
      const draft = [...state.cartItems];
      const existing = !!draft.find(
        ({ productVariant }) =>
          productVariant.id === cartProductItem.productVariant.id
      );
      if (existing) return;

      state.cartItems = [
        ...state.cartItems,
        { ...cartProductItem, quantity: 1, id: cartProductItem?.id ?? '' },
      ];
    },
    incCartItemQty: (
      state,
      action: PayloadAction<{ cartProductItem: ICartProductItem }>
    ) => {
      const { cartProductItem } = action.payload;
      const itemIdx = state.cartItems.findIndex(
        checkIfPCartProductItemMatch(cartProductItem)
      );
      if (itemIdx === -1) return;

      const updated = [...state.cartItems];
      const updateItem = updated[itemIdx];
      updateItem.quantity = updateItem.quantity + 1;
      state.cartItems = updated;
    },
    decCartItemQty: (
      state,
      action: PayloadAction<{ cartProductItem: ICartProductItem }>
    ) => {
      const { cartProductItem } = action.payload;
      const itemIdx = state.cartItems.findIndex(
        checkIfPCartProductItemMatch(cartProductItem)
      );
      if (itemIdx === -1) return;

      const updated = [...state.cartItems];
      const updateItem = updated[itemIdx];
      if (updateItem.quantity === 1) {
        updated.splice(itemIdx, 1);
        state.cartItems = updated;
        return;
      }

      updateItem.quantity = updateItem.quantity - 1;
      state.cartItems = updated;
    },
    removeCartItem: (
      state,
      action: PayloadAction<{ cartProductItem: ICartProductItem }>
    ) => {
      const { cartProductItem } = action.payload;
      const updateCartItems = [...state.cartItems];
      state.cartItems = updateCartItems.filter(
        ({ productVariant }) =>
          productVariant.id !== cartProductItem.productVariant.id
      );
    },
    clearCartItems: (state) => {
      state.cartItems = [];
    },
    setCartItems: (
      state,
      action: PayloadAction<{ cartItems: ICartItem[] }>
    ) => {
      const { cartItems } = action.payload;
      state.cartItems = cartItems;
    },

    // price lock
    setPriceLockTimerDeadline: (state, action: PayloadAction<Dayjs | null>) => {
      state.priceLockTimerDeadline = action.payload;
    },
    decPriceLockTimerDiff: (state, action: PayloadAction<number>) => {
      state.priceLockTimerDiff = state.priceLockTimerDiff - action.payload;
    },
    setPriceLockTimerDiff: (state, action: PayloadAction<number>) => {
      state.priceLockTimerDiff = action.payload;
    },

    // stripe
    setStripePaymentClientSecret: (state, action: PayloadAction<string>) => {
      state.stripePaymentClientSecret = action.payload;
    },
    setStripePaymentIntentId: (state, action: PayloadAction<string>) => {
      state.stripePaymentIntentId = action.payload;
    },
    setCurrencyCode: (state, action: PayloadAction<string>) => {
      state.currencyCode = action.payload;
    },
    setCountryCode: (state, action: PayloadAction<string>) => {
      state.countryCode = action.payload;
    },
    setCurrencyExchangeRate: (state, action: PayloadAction<number>) => {
      state.currencyExchangeRate = action.payload;
    },

    // wishlist
    addVariantToWishlist: (state, action: PayloadAction<number>) => {
      if (!state.wishlistVariantIds) {
        state.wishlistVariantIds = [];
      }
      const variantId = action.payload;
      const exist = !!state.wishlistVariantIds?.find((id) => id === variantId);
      if (exist) return;

      state.wishlistVariantIds = [...state.wishlistVariantIds, variantId];
    },
    removeVariantFromWishlist: (state, action: PayloadAction<number>) => {
      if (!state.wishlistVariantIds) {
        state.wishlistVariantIds = [];
      }
      const variantId = action.payload;
      const exist = !!state.wishlistVariantIds.find((id) => id === variantId);
      if (!exist) return;

      state.wishlistVariantIds = state.wishlistVariantIds.filter(
        (id) => id !== variantId
      );
    },
    clearWishlist: (state) => {
      state.wishlistVariantIds = [];
    },

    setIsSearchInputActive: (state, action: PayloadAction<boolean>) => {
      state.isSearchInputActive = action.payload;
    },
    showSearchInput: (state) => {
      state.showingSearchInput = true;
    },
    hideSearchInput: (state) => {
      state.showingSearchInput = false;
    },
    setSearchInputValue: (state, action: PayloadAction<string>) => {
      state.searchInputValue = action.payload;
    },
    updateRecentSearchTexts: (state, action: PayloadAction<string[]>) => {
      state.recentSearchTexts = action.payload;
    },

    // public cart
    setPublicCart: (
      state,
      action: PayloadAction<IPublicCartCheckoutResponse | null>
    ) => {
      state.publicCart = action.payload;
    },

    clearPersistState: () => {},
  },
});

export const appActions = appSlice.actions;
export const appReducer = appSlice.reducer;
export const useIsCartDrawerOpenSelector = () =>
  useSelector((state: RootState) => state.app.isCartDrawerOpen);

export const useIsRecycleModalOpenSelector = () =>
  useSelector((state: RootState) => state.app.isRecycleModalOpen);
export const useIsAuthModalOpenSelector = () =>
  useSelector((state: RootState) => state.app.isAuthModalOpen);
export const useAuthModalModeSelector = () =>
  useSelector((state: RootState) => state.app.authModalMode);

export const useCartItemsSelector = () =>
  useSelector((state: RootState) => state.app.cartItems);
export const usePriceLockTimerDeadlineSelector = () =>
  useSelector((state: RootState) => state.app.priceLockTimerDeadline);
export const usePriceLockTimerDiffSelector = () =>
  useSelector((state: RootState) => state.app.priceLockTimerDiff);
export const useCurrencyCodeSelector = () =>
  useSelector((state: RootState) => state.app.currencyCode);
export const useCountryCodeSelector = () =>
  useSelector((state: RootState) => state.app.countryCode);
export const useCurrencyExchangeRateSelector = () =>
  useSelector((state: RootState) => state.app.currencyExchangeRate);

export const useStripePaymentClientSecretSelector = () =>
  useSelector((state: RootState) => state.app.stripePaymentClientSecret);
export const useStripePaymentIntentIdSelector = () =>
  useSelector((state: RootState) => state.app.stripePaymentIntentId);

export const useWishlistVariantIdsSelector = () =>
  useSelector((state: RootState) => state.app.wishlistVariantIds);

export const useShowingSearchInputSelector = () =>
  useSelector((state: RootState) => state.app.showingSearchInput);
export const useIsSearchInputActiveSelector = () =>
  useSelector((state: RootState) => state.app.isSearchInputActive);
export const useSearchInputValueSelector = () =>
  useSelector((state: RootState) => state.app.searchInputValue);
export const useRecentSearchTextsSelector = () =>
  useSelector((state: RootState) => state.app.recentSearchTexts);

// public cart
export const usePublicCartSelector = () =>
  useSelector((state: RootState) => state.app.publicCart);
