import {
  ComponentStyleConfig,
  SystemStyleInterpolation,
} from '@chakra-ui/react';
import colors from '@/constants/theme/colors';

const primaryVariant: SystemStyleInterpolation = {
  content: {
    borderColor: colors.grey[200],
    borderRadius: 'none',
    w: 'fit-content',
  },
  header: {},
  body: {
    p: '1.5rem',
  },
  footer: {},
  popper: {
    boxShadow: '0 0.25rem 0.625rem 0.25rem rgba(0, 0, 0, 0.16)',
  },
  arrow: {
    '--popper-arrow-shadow-color': colors.grey[200],
  },
  closeButton: {},
};

const menuVariant: SystemStyleInterpolation = {
  content: {
    borderColor: colors.grey[200],
    borderRadius: 'none',
    w: 'fit-content',
  },
  header: {},
  body: {
    p: '1.5rem',
  },
  footer: {},
  popper: {
    boxShadow: '0px 4px 10px 4px rgba(0, 0, 0, 0.16)',
    zIndex: '9999',
  },
  arrow: {
    '--popper-arrow-shadow-color': colors.grey[200],
  },
  closeButton: {},
};

const dropdownVariant: SystemStyleInterpolation = {
  content: {
    border: 'none',
    borderRadius: '0.125rem',
    w: '100%',
    maxW: '100vw',
  },
  header: {},
  body: {
    p: '1.5rem 1rem',
  },
  footer: {},
  popper: {
    boxShadow: '0 0.063rem 6px 0.063rem rgba(0, 0, 0, 0.08)',
  },
  arrow: {
    display: 'none',
  },
  closeButton: {},
};

const PopoverStyles: ComponentStyleConfig = {
  baseStyle: {},
  variants: {
    primary: primaryVariant,
    dropdown: dropdownVariant,
    menu: menuVariant,
  },
  defaultProps: {
    variant: 'primary',
  },
};

export default PopoverStyles;
