export type IProductTag = 'new' | 'popular';
import {
  IAppProduct,
  IAppProductVariant,
  IAppProductWithVariants,
} from '@/types/api/app/product';
import { IMetal } from '@/types/metal';

export enum IProductCategory {
  bracelets = 'Bracelets',
  necklace = 'Necklace',
  rings = 'Rings',
  earrings = 'Earrings',
  medallions = 'Medallions',
  accessories = 'Accessories',
}

export type IProductItem = {
  productId: number;
  productVariantId: number;
  name: string;
  metalType: IProductMetalType;
  variant?: string;
  thumbnailUrl: string;
  priceValue: number;
  priceCurrency: string;
  isPriceUptrend?: boolean;
  tags?: IProductTag[];
  slug: string;

  product: IAppProductWithVariants;
  productVariant: IAppProductVariant;
};

export type IProductReview = {
  id: string;
  authorName: string;
  authorAvatarUrl?: string;
  rating: number;
  createdAt: string;
  content: string;
};

export type ICartProductItem = {
  id?: string;
  product: IAppProductWithVariants | IAppProduct;
  productVariant: IAppProductVariant;
  metadata?: {
    recipientEmail: string;
    recipientName: string;
    message: string;
  };
};

export type IProductInformation = {
  title: string;
  photoUrls: string[];
  videoUrls: string[];
  supportingPhotoUrls: string[];
  description: string;
  categories: IProductCategory[];
  sku: string;
};

export type IProductSpecification = {
  weight: number;

  productH: number;
  productW?: number;
  productL?: number;
  productThickness?: number;

  packingH: number;
  packingW: number;
  packingL: number;
};

export enum IProductCurrency {
  usd = 'USD',
}

export type IProductPricing = {
  basePriceCurrency: IProductCurrency;
  basePriceValue: number;
};

export type IProductVariant = {
  metalType: IProductMetalType;
  metal: IMetal;
  metalWeight: number;
  totalWeight: number;
};

export enum IProductMetalType {
  gold = 'Gold',
  platinum = 'Platinum',
}

export enum IProductSizing {
  s = 'S',
  m = 'M',
  l = 'L',
}

export type IProduct = {
  id: string;
  slug: string;
  information: IProductInformation;
  specifications: IProductSpecification;
  variants: IProductVariant[];
  sizingOptions: IProductSizing[];
  // base price is calculated from metal
  tags?: IProductTag[];
};
