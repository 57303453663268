'use client';

import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Flex,
  Text,
  Button,
  useDisclosure,
} from '@chakra-ui/react';
import { FC, useCallback, useEffect, useMemo } from 'react';
import {
  appActions,
  useIsCartDrawerOpenSelector,
  useCartItemsSelector,
  useCurrencyCodeSelector,
  useCurrencyExchangeRateSelector,
} from '@/store/slices/app';
import { useDispatch } from 'react-redux';
import colors from '@/constants/theme/colors';
import { ShoppingCartIcon } from '@/components/Icons';
import EmptyState from '@/views/MyAccount/EmptyState';
import { useRouter } from 'next/navigation';
import { PATH } from '@/constants/paths';
import CartItem from '@/components/Cart/CartItem';
import useToast from '@/hooks/useToast';
import {
  getCurrencyPriceFromCartItems,
  getSubTotalPriceFromCartItems,
} from '@/utils/cart';
import TimerDisplay from '@/components/UI/TimerDisplay';
import {
  useCheckoutCartMutation,
  useCheckoutPublicCartMutation,
} from '@/store/apis/app.carts';
import { StockAvailability } from '@/types/api/app/product';
import { useIsLoggedInSelector } from '@/store/slices/user';
import { IAppCartItem } from '@/types/api/app/cart';
import OutOfStockModal from '@/components/Cart/OutOfStockModal';
import { ICartItem } from '@/types/cart';
import { formatPrice } from '@/utils/product';
import { useGetLoggedCustomer } from '@/store/apis/app';
import { skipToken } from '@reduxjs/toolkit/query';
import ReduxProviders from '@/providers/ReduxProvider';
import { filter } from 'lodash';

interface CartDrawerProps {}
const CartDrawerBase: FC<CartDrawerProps> = () => {
  const isOpen = useIsCartDrawerOpenSelector();
  const cartItems = useCartItemsSelector();
  const isLoggedIn = useIsLoggedInSelector();
  const dispatch = useDispatch();
  const router = useRouter();
  const toast = useToast();

  const [checkoutCart, { isLoading: isCheckoutCartLoading }] =
    useCheckoutCartMutation();
  const [checkoutPublicCart, { isLoading: isCheckoutPublicCartLoading }] =
    useCheckoutPublicCartMutation();
  const isCheckingOut = isCheckoutCartLoading || isCheckoutPublicCartLoading;

  const {
    isOpen: isOutOfStockModalOpen,
    onOpen: onOpenOutOfStockModal,
    onClose: onCloseOutOfStockModal,
  } = useDisclosure();

  const handleClose = useCallback(() => {
    dispatch(appActions.closeCartDrawer());
  }, [dispatch]);

  const handleCheckout = useCallback(
    async (cartItems: ICartItem[]) => {
      if (!cartItems.length) {
        toast("Can't checkout with no items", 'info');
        return;
      }

      const outOfStockItems = filter(
        cartItems,
        ({ stockAvailability, productVariant }) =>
          (stockAvailability === StockAvailability.OutOfStock ||
            productVariant.stockAvailability ===
              StockAvailability.OutOfStock) &&
          !productVariant.isPreOrderAllowed
      );

      const hasOutOfStockItem = !!outOfStockItems.length;

      try {
        if (hasOutOfStockItem) {
          onOpenOutOfStockModal();
          return;
        }

        if (isLoggedIn) {
          const resCheckoutCart = await checkoutCart({}).unwrap();

          const {
            stripeClientSecret,
            stripePaymentIntentId,
            cartItems: resCartItems,
          } = resCheckoutCart;

          if (!stripeClientSecret || !stripePaymentIntentId) {
            toast('Failed to initialize checkout', 'error');
          }
          dispatch(appActions.setStripePaymentClientSecret(stripeClientSecret));
          dispatch(appActions.setStripePaymentIntentId(stripePaymentIntentId));
        } else {
          const resCheckoutPublicCart = await checkoutPublicCart({
            body: {
              cartItems: cartItems.map(
                ({
                  productVariant,
                  quantity,
                  subTotalPrice,
                  stockAvailability,
                }) =>
                  ({
                    productVariantId: productVariant.id,
                    quantity,
                    stockAvailability,
                    subTotalPrice,
                  } as IAppCartItem)
              ),
            },
            params: {},
          }).unwrap();
          const { stripeClientSecret, stripePaymentIntentId } =
            resCheckoutPublicCart;

          if (!stripeClientSecret || !stripePaymentIntentId) {
            toast('Failed to initialize checkout', 'error');
          }
          dispatch(appActions.setStripePaymentClientSecret(stripeClientSecret));
          dispatch(appActions.setStripePaymentIntentId(stripePaymentIntentId));
          dispatch(appActions.setPublicCart(resCheckoutPublicCart));
        }

        await router.push(PATH.cartCheckout);
        handleClose();
      } catch (err) {
        toast('Failed to initialize checkout', 'error');
        return;
      }
    },
    [
      handleClose,
      router,
      toast,
      dispatch,
      checkoutCart,
      checkoutPublicCart,
      isLoggedIn,
      onOpenOutOfStockModal,
    ]
  );

  useEffect(() => {
    if (!!isOpen) {
      router.prefetch(PATH.cartCheckout);
      router.prefetch(PATH.shopNewArrivals);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  return (
    <>
      <OutOfStockModal
        isOpen={isOutOfStockModalOpen}
        onClose={onCloseOutOfStockModal}
        onCheckout={handleCheckout}
      />
      <Drawer isOpen={isOpen} placement="right" onClose={handleClose}>
        <DrawerOverlay />
        <DrawerContent position="relative" display="flex" flexDir="column">
          <DrawerCloseButton
            top="2.5rem !important"
            right="2.5rem !important"
          />
          <TimerDisplay position="absolute" left="2.5rem" top="2.75rem" />
          <Flex
            flexDir="column"
            flex="1"
            bgColor={colors.white}
            p="1.25rem"
            pr="0.25rem"
            gap="2rem"
            overflow="auto"
          >
            <DrawerHeader>Cart</DrawerHeader>
            <CartDrawerBody />
          </Flex>
          <CartDrawerFooter
            onCheckout={() => handleCheckout(cartItems)}
            isLoading={isCheckingOut}
          />
        </DrawerContent>
      </Drawer>
    </>
  );
};

interface CartDrawerBodyProps {}
const CartDrawerBody: FC<CartDrawerBodyProps> = () => {
  const dispatch = useDispatch();
  const router = useRouter();
  const cartItems = useCartItemsSelector();

  const handleClose = () => {
    dispatch(appActions.closeCartDrawer());
  };

  const handleShopNow = () => {
    router.push(PATH.shopNewArrivals);
    handleClose();
  };

  // prefetch pages for navigation
  useEffect(() => {
    router.prefetch(PATH.shopNewArrivals);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const hasCartItems = !!cartItems && !!cartItems.length;

  return (
    <DrawerBody>
      {hasCartItems ? (
        <Flex flexDir="column" gap="1.5rem" pr="1rem">
          {cartItems.map((cartItem) => {
            const { product, productVariant } = cartItem;

            const key = `prod_${product?.id}__variant_${productVariant?.id}`;
            return <CartItem cartItem={cartItem} key={key} />;
          })}
        </Flex>
      ) : (
        <EmptyState
          renderIcon={() => (
            <Flex
              alignItems="center"
              justifyContent="center"
              p="0.5rem"
              bgColor={colors.background}
            >
              <ShoppingCartIcon size="1.5rem" color={colors.primary[300]} />
            </Flex>
          )}
          action="Shop Now"
          title="Your Cart is Empty."
          description="We're sure you love our designs!"
          descriptionProps={{
            fontSize: '0.9375rem',
            lineHeight: '1.25rem',
          }}
          onAction={handleShopNow}
          containerProps={{
            justifyContent: 'center',
            h: '100%',
          }}
        />
      )}
    </DrawerBody>
  );
};

interface CartDrawerFooterProps {
  isLoading?: boolean;
  onCheckout?: () => void;
}
const CartDrawerFooter: FC<CartDrawerFooterProps> = ({
  onCheckout,
  isLoading,
}) => {
  const cartItems = useCartItemsSelector();
  const selectedCurrencyCode = useCurrencyCodeSelector();
  const currencyExchangeRate = useCurrencyExchangeRateSelector();

  const subTotalPrice = useMemo(() => {
    if (!cartItems.length) return '';
    const calculatedValue = getSubTotalPriceFromCartItems(cartItems);
    const value = calculatedValue * currencyExchangeRate;
    const currency =
      selectedCurrencyCode ?? getCurrencyPriceFromCartItems(cartItems);
    return `${formatPrice(currency)(value)}`;
  }, [cartItems, selectedCurrencyCode, currencyExchangeRate]);

  return (
    <DrawerFooter>
      <Flex
        flexDir="column"
        gap="1rem"
        w="100%"
        bgColor={colors.white}
        p="1.25rem"
      >
        <Flex alignItems="center" justifyContent="space-between">
          <Text
            color={colors.grey[500]}
            fontSize="1.125rem"
            lineHeight="1.5rem"
          >
            Subtotal
          </Text>
          <Text color={colors.primary[300]} lineHeight="1.5rem">
            {!!subTotalPrice ? subTotalPrice : '-'}
          </Text>
        </Flex>
        <Flex flexDir="column" gap="0.5rem">
          <Button
            variant="primaryLg"
            onClick={onCheckout}
            isLoading={isLoading}
            isDisabled={!cartItems.length}
          >
            Checkout {`(${cartItems.length})`}
          </Button>
          <Text
            color={colors.grey[500]}
            fontSize="0.75rem"
            lineHeight="1rem"
            textAlign="center"
            letterSpacing="-0.06px"
          >
            Shipping and taxes calculated at checkout.
          </Text>
        </Flex>
      </Flex>
    </DrawerFooter>
  );
};

const CartDrawer: FC<CartDrawerProps> = ({ ...props }) => {
  return (
    <ReduxProviders>
      <CartDrawerBase {...props} />
    </ReduxProviders>
  );
};

export default CartDrawer;
